<template>
    <div class="mb-5 pb-5">
        <div class="row justify-content-center" v-show="courseExist">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="card p-0 shadow-xss border-1 rounded-lg overflow-hidden">
                    <div class="card-body py-1">
                        <div class="float-right my-2">
                            <div class="btn dropdown">
                                <button type="button" class="btn btn-current py-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-h"></i>
                                </button>
                                <div class="dropdown-menu">
                                    <router-link class="dropdown-item" :to="'/admin/courses/'+ courses.slug + '/home/edit'"><i class="fas mr-1 fa-edit"></i>Edit</router-link>
                                    <router-link class="dropdown-item" :to="'/admin/courses/'+ courses.slug + '/home/duplicate'"><i class="fas mr-1 fa-edit"></i>Duplicate</router-link>
                                    <button class="dropdown-item" @click="deleteAlert"><i class="fas mr-1 fa-trash"></i>Delete</button>
                                </div>
                            </div>
                            <div class="btn btn-current py-2 px-3 mr-3" @click.prevent="changePublish">
                                <h4 class="d-inline font-xsss mont-font fw-700 text-white mr-3">Publish :</h4>
                                <div class="d-inline float-right">
                                    <span v-if="isChecked" class="badge badge-success"><i class="fas fa-check"></i></span>
                                    <span v-else class="badge badge-danger"><i class="fas fa-times"></i></span>
                                </div>
                            </div>
                            <div class="btn btn-current py-2 px-3" @click.prevent="changeLocked">
                                <h4 class="d-inline font-xsss mont-font fw-700 text-white mr-3">Locked :</h4>
                                <div class="d-inline float-right">
                                    <span v-if="isLocked" class="badge badge-success"><i class="fas fa-check"></i></span>
                                    <span v-else class="badge badge-danger"><i class="fas fa-times"></i></span>
                                </div>
                            </div>
                        </div>

                        <h3 class="fw-700 font-xs mt-3 lh-28 mt-1"><p class="text-current">Course Information </p></h3>
                        <hr>
                        <div class="text-center">
                            <img :src="courses.banner ? courses.banner : 'https://via.placeholder.com/500.png'" class="img-fluid rounded w-50" alt="banner">
                        </div>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Name :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-dark text-grey-700">{{courses.name}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Period :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-dark text-grey-700">{{courses.start_date | formatDate}} - {{courses.end_date | formatDate}} ({{courses.start_time}} - {{courses.end_time}})</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Description :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-dark text-grey-700">{{courses.description}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Cycles :</p></h4>
                        <div class="row">
                            <div class="col-12 mb-1" v-for="(item, index) in cycles" :key="index">
                                <button class="btn btn-sm fw-600 font-xsss" :class="{'btn-success' : item.active, 'btn-danger' : !item.active}"><i class="fas mr-2" :class="{'fa-check' : item.active, 'fa-times' : !item.active}"></i>Cycle {{item.cycle}}</button>
                            </div>
                        </div>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price All Cycles :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price | numFormat('0,0')}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price Cycles 1 :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_cycle_1 | numFormat('0,0')}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price Cycles 2 :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_cycle_2 | numFormat('0,0')}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price Cycles 3 :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_cycle_3 | numFormat('0,0')}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price All Special Cycles :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_special | numFormat('0,0')}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Special Course :</p></h4>
                        <button class="btn btn-sm fw-600 font-xsss" :class="{'btn-success' : courses.has_special, 'btn-danger' : !courses.has_special}"><i class="fas mr-2" :class="{'fa-check' : courses.has_special, 'fa-times' : !courses.has_special}"></i>Special Course</button>
                        <hr>
                        <div class="row" v-if="courses.has_special">
                            <div class="col-12">
                                <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price Special Cycles 1 :</p></h4>
                                <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_special_cycle_1 | numFormat('0,0')}}</p></h5>
                                <hr>
                                <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price Special Cycles 2 :</p></h4>
                                <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_special_cycle_2 | numFormat('0,0')}}</p></h5>
                                <hr>
                                <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Price Special Cycles 3 :</p></h4>
                                <h5 class="fw-600 font-xsss mt-0"><p class="text-success text-success-700">Rp. {{courses.price_special_cycle_3 | numFormat('0,0')}}</p></h5>
                                <hr>
                            </div>
                        </div>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Objective :</p></h4>
                        <div class="fw-600 font-xsss mt-0 text-dark text-grey-700 ql-editor" v-html="courses.objective"></div>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Length :</p></h4>
                        <h5 class="fw-600 font-xsss mt-0"><p class="text-dark text-grey-700">{{courses.length}}</p></h5>
                        <hr>
                        <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Student Menu :</p></h4>
                        <div class="w-100">
                            <div id="accordion" class="accordion p-0">
                                <div class="card border-0 mb-4">
                                    <div class="card-header bg-current pt-2 px-3 cursor-pointer" id="heading1">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed text-white mb-0" id="btn-acc-1" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                                List Menu
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordion">
                                        <div class="card-body bg-lightgrey">
                                            <div class="row">
                                                <div class="col-12">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">Active</th>
                                                                <th class="text-center">Icon</th>
                                                                <th class="text-center">Name</th>
                                                                <th class="text-center">Sub - Menu</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-for="(item, index) in menus" :key="index">
                                                            <tr>
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="item.active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center" width="10%"><i :class="item.icon"></i></td>
                                                                <td class="text-center">{{ item.name }}</td>
                                                                <td class="text-center">-</td>
                                                            </tr>

                                                            <!-- Assessment-task sub-menu -->
                                                            <tr v-if="item.name == 'Assessment Tasks' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="assessmentTaskMenu.fft_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Form-focused Task</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Assessment Tasks'  && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="assessmentTaskMenu.quiz_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Quiz</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Assessment Tasks' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="assessmentTaskMenu.individual_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Individual Task</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Assessment Tasks' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="assessmentTaskMenu.group_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Group Task</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Assessment Tasks' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="assessmentTaskMenu.achievement_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Achievement Test</td>
                                                            </tr>

                                                            <!-- Material Sub-menu -->
                                                            <tr v-if="item.name == 'Materials' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="materialMenu.recorded_video_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Recorded Video</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Materials' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="materialMenu.animated_presentation_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Animated Presentation</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Materials' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="materialMenu.podcast_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">Podcast</td>
                                                            </tr>
                                                            <tr v-if="item.name == 'Materials' && item.active">
                                                                <td class="text-center" width="5%">
                                                                    <input type="checkbox" v-model="materialMenu.smartbook_active" id="checkCycle">
                                                                </td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center"></td>
                                                                <td class="text-center">SmartBook</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="col-12 text-right">
                                                    <a v-if="!isLoadingButton" @click="updateMenu()" class="btn btn-current">Update</a>
                                                    <a v-else class="btn btn-dark disabled">Updating...</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="point.id">
                            <hr>
                            <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Course Point :</p></h4>
                            <h5 class="fw-600 font-xsss mt-0" v-if="point.type == 'AMOUNT'"><p class="text-info">{{point.amount_point | numFormat('0,0')}}</p></h5>
                            <h5 class="fw-600 font-xsss mt-0" v-if="point.type == 'PERCENT'"><p class="text-dark text-grey-700">{{point.amount_point}}%</p></h5>
                            <hr>
                            <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Point Status :</p></h4>
                            <h5 class="fw-600 font-xsss mt-0"><p class="text-dark text-grey-700">{{point.status == 1 ? 'Active' : 'Inactive'}}</p></h5>
                            <hr>
                            <h4 class="fw-700 font-xss mt-3 mt-0"><p class="text-dark text-grey-900">Point Used Limit :</p></h4>
                            <h5 class="fw-600 font-xsss mt-0"><p class="text-dark text-grey-700">{{point.used_limit}}</p></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center d-flex" v-show="courseNotExist">
            <div class="col-xl-12 col-lg-12 text-center py-3">
                <div class="my-3" v-show="loadCourse">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>

                </div>
                <img :src="'/images/empty.png'" v-show="courseNotExist" alt="" width="300">
                <h1 class="text-muted" v-show="courseNotExist">Data don't exist</h1>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Courses",
        data(){
            return {
                courses: {},
                cycles: [],
                menus: [],
                point:{
                    id: 0,
                    amount_point: 0,
                    course_id: 0,
                    status: 0,
                    type: "",
                    used_limit: 0
                },
                isChecked : true,
                isLocked : true,
                media: process.env.VUE_APP_URL_CLOUD,
                courseExist: false,
                courseNotExist: false,
                loadCourse: true,
                paramsId: this.$route.params.idCourse,
                assessmentTaskMenu: null,
                materialMenu: null,
                isLoadingButton: false
            }
        },
        mounted() {
            this.getCourse(),
            this.getAssessmentTaskMenu(),
            this.getMaterialMenu()
        },
        methods: {
            async getAssessmentTaskMenu(){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course/assessment-task-menus?slug=` + this.paramsId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.data){
                        this.assessmentTaskMenu = {
                            fft_active: res.data.data.fft_active,
                            quiz_active: res.data.data.quiz_active,
                            individual_active: res.data.data.individual_active,
                            group_active: res.data.data.group_active,
                            achievement_active: res.data.data.achievement_active
                        }
                    } else {
                        this.assessmentTaskMenu = {
                            fft_active: true,
                            quiz_active: true,
                            individual_active: true,
                            group_active: true,
                            achievement_active: true
                        }
                    }
                })
            },
            async getMaterialMenu(){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course/material-menus?slug=` + this.paramsId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.data){
                        this.materialMenu = {
                            recorded_video_active: res.data.data.recorded_video_active,
                            animated_presentation_active: res.data.data.animated_presentation_active,
                            podcast_active: res.data.data.podcast_active,
                            smartbook_active: res.data.data.smartbook_active
                        }
                    } else {
                        this.materialMenu = {
                            recorded_video_active: true,
                            animated_presentation_active: true,
                            podcast_active: true,
                            smartbook_active: true
                        }
                    }
                })
            },
            async updateAssessmentTaskMenu(){
                await axios.patch(`${process.env.VUE_APP_URL_API}/admin/course/assessment-task-menus?slug=` + this.paramsId, this.assessmentTaskMenu, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                })
            },
            async updateMaterialMenu(){
                await axios.patch(`${process.env.VUE_APP_URL_API}/admin/course/material-menus?slug=` + this.paramsId, this.materialMenu, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                })
            },
            async getCourse(){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course?slug=` + this.paramsId, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if (res.data.length == 0) {
                        this.courseNotExist = true
                    } else {
                        this.courses = res.data
                        this.isChecked = this.courses.publish
                        this.isLocked = this.courses.locked
                        this.getPoint(this.courses.id)
                        this.getCycles(this.courses.slug)
                        this.getMenus(this.courses.slug)
                        this.courseExist = true
                    }
                    this.loadCourse = false
                }).catch((err) => {
                    this.loadCourse = false
                    if (err.response.status == 404) {
                        this.courseNotExist = true
                    }
                })
            },
            async getPoint(id){
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/point?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.status == 200) {
                        this.point = res.data.data
                    }
                }).catch((err) => console.error(err.response))
            },
            async getCycles(slug){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course/cycles?slug=${slug}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.status == 200) {
                        this.cycles = res.data.data
                    }
                }).catch((err) => console.error(err.response))
            },
            async getMenus(slug){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/course/menus?slug=${slug}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.status == 200) {
                        this.menus = res.data.data
                    }
                }).catch((err) => console.error(err.response))
            },
            async updateMenu() {
                this.isLoadingButton = true
                var data  = {
                    slug: this.paramsId,
                    menus: this.menus,
                    _method: 'patch'
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/course/menus`, data, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(async res => {
                    if(res.status == 200) {
                        await this.updateAssessmentTaskMenu()
                        await this.updateMaterialMenu()
                        this.$swal({
                            toast: true,
                            title: 'Course Student Menus',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                }).catch(err => {
                    if(err.response.status == 422) {
                        this.$swal({
                            toast: true,
                            title: 'Course Student Menus',
                            text: err.response.data.message[0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    } else {
                        console.log(err)
                    }
                })
                this.isLoadingButton = false
            },
            changePublish(){
                let title = !this.isChecked ? 'publish' : 'unpublish';
                this.$swal({
                    title: 'Are you sure to '+title+' this course ?',
                    text: 'You can\'t revert your action',
                    showCancelButton: true,
                    confirmButtonText: 'Yes Change it!',
                    cancelButtonText: 'No, Keep it!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        var data  = {
                            id: this.courses.id,
                            publish: !this.isChecked,
                            _method: 'patch'
                        }
                        await axios.post(`${process.env.VUE_APP_URL_API}/admin/course/publish`, data, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.token}`
                            },
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }).then((result) => {
                    if(result.value) {
                        this.getCourse()
                        this.$swal({
                            toast: true,
                            title: 'Success Update This Course !',
                            text: 'Successfully change courses',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    } else {
                        this.$swal({
                            toast: true,
                            title: 'Cancelled',
                            text: 'Your data is still intact',
                            icon: 'info',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                })
            },
            changeLocked(){
                let title = !this.isLocked ? 'locked' : 'unlocked';
                this.$swal({
                    title: 'Are you sure to '+title+' this course ?',
                    text: 'You can\'t revert your action',
                    showCancelButton: true,
                    confirmButtonText: 'Yes Change it!',
                    cancelButtonText: 'No, Keep it!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        var data  = {
                            id: this.courses.id,
                            locked: !this.isLocked,
                            _method: 'patch'
                        }
                        await axios.post(`${process.env.VUE_APP_URL_API}/admin/course/locked`, data, {
                            headers: {
                                'Authorization': `Bearer ${localStorage.token}`
                            },
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                }).then((result) => {
                    if(result.value) {
                        this.getCourse()
                        this.$swal({
                            toast: true,
                            title: 'Success Update This Course !',
                            text: 'Successfully change courses',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    } else {
                        this.$swal({
                            toast: true,
                            title: 'Cancelled',
                            text: 'Your data is still intact',
                            icon: 'info',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                })
            },
            deleteAlert() {
                this.$swal({
                    title: 'Are you sure?',
                    text: 'You can\'t revert your action',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes Delete it!',
                    cancelButtonText: 'No, Keep it!',
                    showCloseButton: true,
                    showLoaderOnConfirm: true
                    }).then((result) => {
                    if(result.value) {
                        this.deleteCourse()
                    } else {
                        this.$swal({
                            toast: true,
                            title: 'Cancelled',
                            text: 'Your data is still intact',
                            icon: 'info',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                })
            },
            deleteCourse(){
                axios.delete(`${process.env.VUE_APP_URL_API}/admin/course?id=` + this.courses.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Success Delete This Course !',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.$router.push({ name: 'CoursesAdmin' })
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        // this.$toastr.error(this.validationErrors[0][0], 'Failed Delete This Course !');
                        this.$swal({
                            toast: true,
                            title: 'Failed Delete This Course !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                    console.log(err.response)
                })
            }
        }
    }
</script>

<style>
.ql-editor {
    min-height: 0;
}

.ql-editor>* {
    cursor: text
}

.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol,
.ql-editor ul {
    padding-left: 1.5em
}

.ql-editor ol>li,
.ql-editor ul>li {
    list-style-type: none
}

.ql-editor ul>li::before {
    content: '\2022'
}

.ql-editor li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em
}

.ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.ql-editor ol li:before {
    content: counter(list-0, decimal) '. '
}

.ql-editor ol li.ql-indent-1 {
    counter-increment: list-1
}

.ql-editor ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-2 {
    counter-increment: list-2
}

.ql-editor ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. '
}

.ql-editor ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-3 {
    counter-increment: list-3
}

.ql-editor ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. '
}

.ql-editor ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-4 {
    counter-increment: list-4
}

.ql-editor ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-5 {
    counter-increment: list-5
}

.ql-editor ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. '
}

.ql-editor ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-6 {
    counter-increment: list-6
}

.ql-editor ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. '
}

.ql-editor ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9
}

.ql-editor ol li.ql-indent-7 {
    counter-increment: list-7
}

.ql-editor ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. '
}

.ql-editor ol li.ql-indent-7 {
    counter-reset: list-8 list-9
}

.ql-editor ol li.ql-indent-8 {
    counter-increment: list-8
}

.ql-editor ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. '
}

.ql-editor ol li.ql-indent-8 {
    counter-reset: list-9
}

.ql-editor ol li.ql-indent-9 {
    counter-increment: list-9
}

.ql-editor ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. '
}

</style>
